import React, { useState, useEffect, Suspense, lazy } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { hot } from "react-hot-loader/root";

import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

import Loading from "./loading";

import Nav from "../_components/nav.js";
import Copyright from "../_components/copyright.js";

import PageHeader from "./_editorial/pageHeader";
import Header from "./_editorial/header.js";
import SubHeader from "./_editorial/subHeader.js";

const About = lazy(() => import("./about"));

const DigitalCurrency = lazy(() =>
  import("./_projects/digitalCurrency/index.js")
);

// const Test = lazy(() => import("./_projects/test/index.js"));
const Style = lazy(() => import("./_projects/style/index.js"));

const MmmmLisa = lazy(() => import("./_projects/mmmmLisa/index.js"));
const YearPercentage = lazy(() =>
  import("./_projects/yearPercentage/index.js")
);

const mainVariants = {
  navOpen: { opacity: 0.2, x: "50%", cursor: "pointer" },
  navClosed: { opacity: 1, x: "0px", cursor: "auto" },
};

export default hot(() => {
  const [navOpen, set_navOpen] = useState(false);
  const toggleNav = () => {
    console.log("toggleNav");
    if (navOpen) {
      set_navOpen(false);
    } else {
      set_navOpen(true);
    }
  };
  useEffect(() => {
    if (isMobile) {
      // mainVariants.navOpen.x = "50%";
    }
  }, []);

  return (
    <Router basename="/">
      <>
        <Nav
          navOpen={navOpen}
          set_navOpen={set_navOpen}
          toggleNav={toggleNav}
          isMobile={isMobile}
        />
        <Copyright />

        <Suspense fallback={<Loading />}>
          <motion.div
            id="main"
            animate={navOpen ? "navOpen" : "navClosed"}
            initial={navOpen ? "navOpen" : "navClosed"}
            variants={mainVariants}
            transition={{ type: "spring", stiffness: 100, duration: 1 }}
            onClick={() => {
              console.log(navOpen);
              if (navOpen) {
                toggleNav();
              }
            }}
          >
            <Switch>
              <Route exact path="/">
                <About navOpen={navOpen} />
              </Route>
              {/* EXPERIMENTS */}
              {/* ///////////////////////////////////////////// */}
              {/* <Route exact path="/eyesonscreen">
                <EyesOnScreen />
              </Route> */}
              <Route exact path="/yearPercentage">
                <YearPercentage />
              </Route>
              <Route exact path="/cashisdead">
                <DigitalCurrency />
              </Route>
              {/* <Route exact path="/MmmmLisa">
                <MmmmLisa />
              </Route> */}
              <Route exact path="/style">
                <Style />
              </Route>
              <Route exact path="/test">
                <h1>Test</h1>
              </Route>
              {/* 404 */}
              {/* ///////////////////////////////////////////// */}
              <Route path="*">
                <PageHeader text="404" break={true} />
                <SubHeader
                  text="404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
            404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
            404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
            404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404"
                  break={true}
                />
              </Route>
            </Switch>
          </motion.div>
        </Suspense>
      </>
    </Router>
  );
});
