import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  return (
    <div id="logo" className="noSelect">
      <Link
        to={"./"}
        onClick={() => {
          if (props.navClicked) {
            props.navClicked();
          }
        }}
        className="noSelect"
      >
        <img
          src="../_images/jgLogo.svg"
          alt="@jamiegalbreath"
          className="noSelect"
        />
      </Link>
    </div>
  );
};
