import React from "react";
import Fade from "react-reveal/Fade";

export default (props) => {
  // console.log(props);
  return (
    <Fade bottom cascade>
      <div className="">
        <h3 className="header">{props.text}</h3>
      </div>
    </Fade>
  );
};
