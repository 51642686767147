import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";
import Logo from "./logo";

import { Link } from "react-router-dom";
import emailScramble from "email-scramble";
import Image from "./_editorial/image";

export default (props) => {
  console.log(props);

  const [navTrigger, set_navTrigger] = useState(true);

  useEffect(() => {
    if (!navTrigger) {
      setTimeout(() => {
        set_navTrigger(true);
      }, 1300);
    }
  }, [navTrigger]);

  useEffect(() => {
    calSectionContentHeights();
    decodeEmail();
  }, []);

  const decodeEmail = () => {
    var links = document.querySelectorAll("[data-email-scramble]");

    links.forEach(function decodeLink(link) {
      link.href = emailScramble.decode(link.href);
    });
  };

  const navClicked = () => {
    set_navTrigger(false);
    props.set_navOpen(false);
    window.scrollTo(0, 0);
  };

  const calSectionContentHeights = () => {
    var sectionContent = document.getElementsByClassName("navSectionContent");

    Array.from(sectionContent).forEach((element) => {
      element.style.height =
        element.getElementsByTagName("a").length * 24 + 30 + "px";
    });
  };

  return (
    <div
      id="nav"
      className={"noSelect " + (props.navOpen ? "navOpen" : "navClosed")}
      onClick={() => {
        if (!props.isMobile) {
          props.toggleNav();
        }
      }}
    >
      <div
        id="navTrigger"
        className="noSelect"
        onMouseEnter={() => {
          if (!props.isMobile && !props.navOpen && navTrigger) {
            props.set_navOpen(true);
          }
        }}
      ></div>

      <Logo navClicked={navClicked} />

      <div
        id="navMenu"
        className="noSelect"
        onClick={() => {
          props.toggleNav();
        }}
        onMouseEnter={() => {
          if (!props.navOpen && navTrigger) {
            props.set_navOpen(true);
          }
        }}
      >
        <div className="navSection noSelect">
          <div className="navSectionContent noSelect">
            <span className="navSectionHead noSelect">Experiments</span>
            {/* <Link
              to={"./style"}
              onClick={() => {
                navClicked();
              }}
            >
              Style
            </Link> */}
            <Link
              to={"./yearPercentage"}
              onClick={() => {
                navClicked();
              }}
            >
              year %
            </Link>
            {/* <a href="https://www.prescast.com" target="new">
              Prescast
            </a> */}
            <Link
              to={"./cashisdead"}
              onClick={() => {
                navClicked();
              }}
            >
              Cash is Dead
            </Link>
            {/* <Link
              to={"./MmmmLisa"}
              onClick={() => {
                navClicked();
              }}
            >
              Mmmm Lisa
            </Link> */}
          </div>
        </div>
        <div className="navSection noSelect">
          <div className="navSectionContent noSelect">
            <span className="navSectionHead noSelect">Portfolio</span>
            <a
              href="znvygb:pbagnpg@wnzvrtnyoerngu.pbz?fhowrpg=Erdhrfg%75Cbegsbyvb&obql=Pna%75lbh%75fraq%75lbhe%75sbyvb%75zl%75jnl.%75Gunaxf!"
              target="new"
              data-email-scramble
            >
              On Request
            </a>
          </div>
        </div>
        <div className="navSection noSelect">
          <div className="navSectionContent noSelect">
            <span className="navSectionHead noSelect">Contact</span>
            {/* {scramble} */}
            <a
              href="znvygb:pbagnpg@wnzvrtnyoerngu.pbz"
              target="new"
              data-email-scramble
            >
              Email me
            </a>
            <a href="https://www.instagram.com/jamiegalbreath/" target="new">
              Instagram
            </a>
            <a href="https://twitter.com/jamiegalbreath" target="new">
              Twitter
            </a>
            <a href="https://www.linkedin.com/in/jamiegalbreath/" target="new">
              Linkedin
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
