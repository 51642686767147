import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

// CSS

import "./_css/brand.css";
import "./_css/editorial.css";

import "./_css/nav.css";
import "./_css/main.css";

import "./_css/copyright.css";
import "./_css/loading.css";

import App from "./_components/_App.js";
import Firebase, { FirebaseContext } from "./_components/_firebase";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
