import React from "react";
import Fade from "react-reveal/Fade";

export default (props) => {
  // console.log(props);

  return (
    <Fade left>
      <div className="pageHeader">
        <h3>{props.text}</h3>
      </div>
    </Fade>
  );
};
