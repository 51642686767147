import React, { useEffect } from "react";

import Reveal from "react-reveal/Reveal";

export default (props) => {
  switch (props.type) {
    case "halfWidth":
      return (
        <div className="">
          <Reveal effect="fadeInUp" duration={1000}>
            <img src={props.path} alt={props.alt} className="image halfWidth" />
          </Reveal>
        </div>
      );

    case "halfHeight":
      return (
        <Reveal effect="fadeInUp" duration={1000}>
          <div
            className="halfHeight"
            style={{ backgroundImage: "url(" + props.path + ")" }}
          ></div>
        </Reveal>
      );

    case "double":
      return (
        <>
          <div className="double">
            <Reveal effect="fadeInUp" duration={1100}>
              <img src={props.path1} alt={props.alt1} className="image" />
            </Reveal>
            <Reveal effect="fadeInUp" duration={900}>
              <img src={props.path2} alt={props.alt2} className="image" />
            </Reveal>
          </div>
        </>
      );

    case "triple":
      return (
        <div className="triple">
          <Reveal effect="fadeInUp" duration={800}>
            <img src={props.path1} alt={props.alt1} className="image" />
          </Reveal>
          <Reveal effect="fadeInUp" duration={1200}>
            <img src={props.path2} alt={props.alt2} className="image" />
          </Reveal>
          <Reveal effect="fadeInUp" duration={1000}>
            <img src={props.path3} alt={props.alt3} className="image" />
          </Reveal>
        </div>
      );

    default:
      return (
        <Reveal effect="fadeInUp" duration={1000}>
          <img src={props.path} alt={props.alt} className="image" />
        </Reveal>
      );
  }
};
