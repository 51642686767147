import React, { useState, useEffect } from "react";

export default (props) => {
  return (
    <>
      <div id="copyright" className="noSelect">
        <span>©2021&nbsp;jamiegalbreath</span>
      </div>
    </>
  );
};
