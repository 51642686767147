import React from "react";
import Fade from "react-reveal/Fade";

export default (props) => {
  // console.log(props);
  return (
    <Fade bottom cascade>
      <div>
        <h2 className="subHeader">{props.text}</h2>
      </div>
    </Fade>
  );
};
